'use client';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';

import { cn } from '@/utils/ui';

import Icon from '../Icon';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-helsinki-blue-dark/60 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    readonly hideCloseButton?: boolean;
    readonly size?: 'md' | 'lg';
  }
>(({ className, children, hideCloseButton = false, size, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
    <div className="responsive-grid container fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2  duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-1/2 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-1/2">
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'no-scrollbar relative col-span-4 grid max-h-[calc(100dvh_-_120px)] w-full gap-5 overflow-y-scroll rounded bg-white shadow-1xl',
          size === 'lg' && 'p-16 desktop:col-span-8 desktop:col-start-3',
          size === 'md' && 'p-6 desktop:col-span-6 desktop:col-start-4',
          className,
        )}
        {...props}
      >
        {children}
        {!hideCloseButton && (
          <DialogPrimitive.Close
            data-testid="modal-close"
            className="absolute right-6 top-6 rounded ring-offset-white transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-helsinki-blue focus:ring-offset-2 disabled:pointer-events-none"
          >
            <Icon
              icon="CROSS"
              width={24}
              height={24}
              color="helsinki-blue"
              variant="glyph"
            />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        )}
      </DialogPrimitive.Content>
    </div>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col', className)} {...props} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & {
    readonly Icon?: React.ReactNode;
  }
>(({ className, Icon: TitleIcon, ...props }, ref) => (
  <div className="flex items-center gap-2">
    {TitleIcon}
    <DialogPrimitive.Title
      ref={ref}
      className={cn('text-2xl leading-[38px] text-helsinki-blue', className)}
      {...props}
    />
  </div>
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn(
      'mt-3 text-base font-extralight leading-normal text-gray',
      className,
    )}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
};
