'use client';

import NextLink from 'next/link';
import type { RichTranslationValues } from 'next-intl';
import type { ReactNode } from 'react';

import { Typography } from '@/app/components/ui/Typography';

const getLocaleFormatting: RichTranslationValues = {
  // In Lokalise, these tokens are simple text replacements for values wrapped in curly braces like {tm}
  r: String.fromCharCode(174),
  tm: String.fromCharCode(8482),
  // Simple HTML tags (i.e. <b>bold text</b>)
  break: function breakTag() {
    return <br />;
  },
  wbreak: function wbreakTag() {
    return <wbr />;
  },
  strong: function strong(chunks: ReactNode) {
    return <strong>{chunks}</strong>;
  },
  b: function b(chunks: ReactNode) {
    return <strong>{chunks}</strong>;
  },
  em: function em(chunks: ReactNode) {
    return <em className="font-serif italic">{chunks}</em>;
  },
  i: function i(chunks: ReactNode) {
    return <em>{chunks}</em>;
  },
  u: function u(chunks: ReactNode) {
    return <span className="underline">{chunks}</span>;
  },
  sup: function sup(chunks: ReactNode) {
    return <sup>{chunks}</sup>;
  },
  li: function li(chunks: ReactNode) {
    return <li>{chunks}</li>;
  },

  // React Components & more complex HTML tag usage
  Link: function Link(href: ReactNode): JSX.Element {
    return (
      <NextLink href={href as string}>
        <Typography>{href}</Typography>
      </NextLink>
    );
  },

  ExternalLink: function ExternalLink(href: ReactNode): JSX.Element {
    return (
      <NextLink href={href as string} target="_blank" rel="noreferrer">
        <Typography>{href}</Typography>
      </NextLink>
    );
  },

  EmailLink: function EmailLink(email: ReactNode): JSX.Element {
    return (
      <NextLink href={`mailto:${email as string}`}>
        <Typography>{email}</Typography>
      </NextLink>
    );
  },

  HomeLink: function HomeLink(chunks: ReactNode): JSX.Element {
    return (
      <NextLink href="/" color="inherit">
        <Typography>{chunks}</Typography>
      </NextLink>
    );
  },

  TermsLink: function TermsLink(text: ReactNode): JSX.Element {
    return (
      <NextLink href="/terms-and-conditions">
        <Typography>{text}</Typography>
      </NextLink>
    );
  },

  IpNoticeLink: function IpNoticeLink(text: ReactNode): JSX.Element {
    return (
      <NextLink href="/intellectual-property-notice">
        <Typography>{text}</Typography>
      </NextLink>
    );
  },
};

export default getLocaleFormatting;
