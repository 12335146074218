import Divider from '@/app/components/ui/Divider';

import { DialogDescription, DialogHeader, DialogTitle } from '..';

interface HeaderProps {
  readonly title?: string;
  readonly description?: string;
  readonly TitleIcon?: React.ReactNode;
}

const DialogHeaderGroup = ({ title, description, TitleIcon }: HeaderProps) => {
  return (
    <DialogHeader>
      {title && <DialogTitle Icon={TitleIcon}>{title}</DialogTitle>}
      {description && <DialogDescription>{description}</DialogDescription>}

      <Divider color="gray-5" className="mt-4" />
    </DialogHeader>
  );
};

export { DialogHeaderGroup };
