import { Button } from '@/app/components/ui/Button';
import Divider from '@/app/components/ui/Divider';

import { DialogClose, DialogFooter } from '..';

interface DialogFooterGroupProps {
  readonly footerActionCallback?: (e: React.MouseEvent<HTMLElement>) => void;
  readonly footerActionDisabled?: boolean;
  readonly footerActionLabel?: string;
  readonly footerActionLoading?: boolean;
  readonly footerCloseCallback?: (e: React.MouseEvent<HTMLElement>) => void;
  readonly footerCloseLabel?: string;
  readonly showDivider?: boolean;
}

const DialogFooterGroup = ({
  footerActionCallback,
  footerActionDisabled = false,
  footerActionLabel,
  footerActionLoading = false,
  footerCloseCallback,
  footerCloseLabel,
  showDivider,
}: DialogFooterGroupProps) => {
  return (
    <DialogFooter>
      {showDivider && <Divider color="gray-5" className="mb-4" />}
      <div className="flex flex-col-reverse gap-3 desktop:flex-row desktop:gap-4 desktop:self-end">
        {!!footerCloseCallback && (
          <DialogClose
            asChild
            className="desktop:w-fit"
            data-testid="modal-footer-close"
          >
            <Button variant="outline" onClick={footerCloseCallback}>
              {footerCloseLabel ?? 'Close'}
            </Button>
          </DialogClose>
        )}
        {!!footerActionCallback && (
          <DialogClose asChild className="desktop:w-fit">
            <Button
              disabled={footerActionDisabled}
              onClick={footerActionCallback}
              loading={footerActionLoading}
              data-testid="modal-footer-action"
            >
              {footerActionLabel ?? 'Accept'}
            </Button>
          </DialogClose>
        )}
      </div>
    </DialogFooter>
  );
};

export { DialogFooterGroup };
