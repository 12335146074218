'use client';

import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/utils/ui';

const dividerVariants = cva('', {
  variants: {
    color: {
      'burnt-orange-25': 'bg-burnt-orange-25',
      'burnt-orange-5': 'bg-burnt-orange-5',
      'burnt-orange-50': 'bg-burnt-orange-50',
      'burnt-orange-80': 'bg-burnt-orange-80',
      'burnt-orange': 'bg-burnt-orange',
      'enzo-blue-25': 'bg-enzo-blue-25',
      'enzo-blue-5': 'bg-enzo-blue-5',
      'enzo-blue-50': 'bg-enzo-blue-50',
      'enzo-blue-80': 'bg-enzo-blue-80',
      'enzo-blue': 'bg-enzo-blue',
      'enzo-bright-25': 'bg-enzo-bright-25',
      'enzo-bright-5': 'bg-enzo-bright-5',
      'enzo-bright-50': 'bg-enzo-bright-50',
      'enzo-bright-80': 'bg-enzo-bright-80',
      'enzo-bright': 'bg-enzo-bright',
      'gray-15': 'bg-gray-15',
      'gray-25': 'bg-gray-25',
      'gray-5': 'bg-gray-5',
      'gray-50': 'bg-gray-50',
      'gray-80': 'bg-gray-80',
      'green-success-25': 'bg-green-success-25',
      'green-success-5': 'bg-green-success-5',
      'green-success-50': 'bg-green-success-50',
      'green-success-50-shade': 'bg-green-success-50-shade',
      'green-success-80': 'bg-green-success-80',
      'green-success': 'bg-green-success',
      'helsinki-blue-25': 'bg-helsinki-blue-25',
      'helsinki-blue-5': 'bg-helsinki-blue-5',
      'helsinki-blue-50': 'bg-helsinki-blue-50',
      'helsinki-blue-80': 'bg-helsinki-blue-80',
      'helsinki-blue-dark': 'bg-helsinki-blue-dark',
      'helsinki-blue-light': 'bg-helsinki-blue-light',
      'helsinki-blue': 'bg-helsinki-blue',
      'living-coral-25': 'bg-living-coral-25',
      'living-coral-5': 'bg-living-coral-5',
      'living-coral-50': 'bg-living-coral-50',
      'living-coral-80': 'bg-living-coral-80',
      'living-coral': 'bg-living-coral',
      sandstone: 'bg-sandstone',
      'sandstone-25': 'bg-sandstone-25',
      'sandstone-5': 'bg-sandstone-5',
      'sandstone-50': 'bg-sandstone-50',
      'sandstone-80': 'bg-sandstone-80',
      'sandstone-light': 'bg-sandstone-light',
    },
  },
  defaultVariants: {
    color: 'gray-15',
  },
});

const Divider = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  VariantProps<typeof dividerVariants> & {
    readonly orientation?: 'horizontal' | 'vertical';
    readonly className?: string;
    readonly isAccessible?: boolean;
  }
>(
  (
    {
      className,
      orientation = 'horizontal',
      isAccessible = true,
      color,
      ...props
    },
    ref,
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={isAccessible}
      orientation={orientation}
      className={cn(
        `shrink-0`,
        orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
        dividerVariants({ color }),
        className,
      )}
      {...props}
    />
  ),
);
Divider.displayName = SeparatorPrimitive.Root.displayName;

export default Divider;
