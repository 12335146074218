'use client';

import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { useToggle } from 'usehooks-ts';

import { useIsBlocked } from '@/app/components/NavigationBlock';
import UnsavedChangesWarning from '@/app/components/UnsavedChangesWarning';

/**
 * A custom Link component that wraps Next.js's next/link component.
 */
export function Link({
  href,
  children,
  replace,
  ...rest
}: Parameters<typeof NextLink>[0]) {
  const router = useRouter();
  const { isBlocked } = useIsBlocked();
  const [isOpen, toggleOpen] = useToggle();

  const handleRoute = useCallback(() => {
    const url = href.toString();
    if (replace) {
      router.replace(url);
    } else {
      router.push(url);
    }
  }, [href, replace, router]);

  return (
    <>
      <NextLink
        href={href}
        onClick={(e) => {
          e.preventDefault();
          // Cancel navigation
          if (isBlocked) {
            toggleOpen();
            return;
          }
          handleRoute();
        }}
        {...rest}
      >
        {children}
      </NextLink>

      <UnsavedChangesWarning
        open={isOpen}
        onConfirm={() => {
          handleRoute();
          toggleOpen();
        }}
        onCancel={toggleOpen}
      />
    </>
  );
}
