'use client';

import { useRouter } from 'next/navigation';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useToggle } from 'usehooks-ts';

import UnsavedChangesWarning from '@/app/components/UnsavedChangesWarning';

const NavigationBlockerContext = createContext<
  [isBlocked: boolean, setBlocked: Dispatch<SetStateAction<boolean>>]
>([false, () => {}]);

export function NavigationBlockerProvider({
  children,
}: {
  readonly children: React.ReactNode;
}) {
  const state = useState(false);
  return (
    <NavigationBlockerContext.Provider value={state}>
      {children}
    </NavigationBlockerContext.Provider>
  );
}

export function useIsBlocked() {
  const [isBlocked, setIsBlocked] = useContext(NavigationBlockerContext);
  return { isBlocked, setIsBlocked };
}

export function Blocker({ isBlocked }: { readonly isBlocked: boolean }) {
  const [, setBlocked] = useContext(NavigationBlockerContext);

  useEffect(() => {
    setBlocked(isBlocked);
    return () => {
      setBlocked(false);
    };
  }, [isBlocked, setBlocked]);

  return null;
}

export function BlockBrowserNavigation({
  backHref,
}: {
  readonly backHref?: string;
}) {
  const { isBlocked } = useIsBlocked();
  const router = useRouter();
  const [isOpen, toggleOpen] = useToggle();

  useEffect(() => {
    // Used to make popstate event trigger when back button is clicked.
    // Without this, the popstate event will not fire because it needs there to be a href to return
    if (typeof window !== 'undefined' && isBlocked) {
      window.history.pushState(null, document.title, window.location.href);
    }
  }, [isBlocked]);

  // @ts-expect-error - Only add event listeners when isBlocked is true
  useEffect(() => {
    if (isBlocked) {
      // Used to prevent navigation when `back` browser button is clicked
      const handlePopState = () => {
        window.history.pushState(null, document.title, window.location.href);
        toggleOpen();
      };
      // Used to prevent navigation when reload page or navigate to another page
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [isBlocked]);

  return (
    <UnsavedChangesWarning
      open={isOpen}
      onConfirm={() => {
        router.push(backHref ?? '/');
        toggleOpen();
      }}
      onCancel={toggleOpen}
    />
  );
}
