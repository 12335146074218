import type { PropsWithChildren } from 'react';

import { Dialog, DialogContent, DialogTrigger } from '..';
import { DialogFooterGroup } from './DialogFooterGroup';
import { DialogHeaderGroup } from './DialogHeaderGroup';

type TDialogComposedProps = PropsWithChildren & {
  readonly Tigger?: React.ReactNode;
  readonly TitleIcon?: React.ReactNode;
  readonly description?: string;
  readonly footerActionCallback?: (e: React.MouseEvent<HTMLElement>) => void;
  readonly footerActionDisabled?: boolean;
  readonly footerActionLabel?: string;
  readonly footerActionLoading?: boolean;
  readonly footerCloseCallback?: (e: React.MouseEvent<HTMLElement>) => void;
  readonly footerCloseLabel?: string;
  readonly hideCloseButton?: boolean;
  readonly onOpenChange?: (open: boolean) => void;
  readonly open?: boolean;
  readonly size?: 'md' | 'lg';
  readonly title?: string;
};

const DialogComposed = ({
  Tigger,
  TitleIcon,
  children,
  description,
  footerActionCallback,
  footerActionDisabled,
  footerActionLabel,
  footerActionLoading,
  footerCloseCallback,
  footerCloseLabel,
  hideCloseButton = false,
  onOpenChange,
  open,
  size = 'md',
  title,
}: TDialogComposedProps) => {
  const showTitle = !!title || !!description;
  const showFooter = !!footerActionCallback || !!footerCloseCallback;
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      {Tigger && <DialogTrigger asChild>{Tigger}</DialogTrigger>}
      <DialogContent hideCloseButton={hideCloseButton} size={size}>
        {showTitle && (
          <DialogHeaderGroup
            title={title}
            description={description}
            TitleIcon={TitleIcon}
          />
        )}
        {children}
        {showFooter && (
          <DialogFooterGroup
            footerActionCallback={footerActionCallback}
            footerActionDisabled={footerActionDisabled}
            footerActionLabel={footerActionLabel}
            footerActionLoading={footerActionLoading}
            footerCloseCallback={footerCloseCallback}
            footerCloseLabel={footerCloseLabel}
            showDivider={!!children}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export { DialogComposed };
