'use client';

import { useRouter } from 'next/navigation';
import { signOut } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useToggle } from 'usehooks-ts';

import { useIsBlocked } from '@/app/components/NavigationBlock';
import Avatar from '@/app/components/ui/Avatar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/app/components/ui/Popover';
import UnsavedChangesWarning from '@/app/components/UnsavedChangesWarning';

type UserMenuProps = { readonly initials: string };

export default function UserMenu({ initials }: UserMenuProps) {
  const t = useTranslations();
  const router = useRouter();
  const { isBlocked } = useIsBlocked();
  const [isOpen, toggleOpen] = useToggle();

  const logout = async () => {
    const { url } = await signOut({
      callbackUrl: '/signin',
      redirect: false,
    });
    router.push(url);
  };

  const handleClick = () => {
    if (isBlocked) {
      toggleOpen();
    } else {
      void logout();
    }
  };

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <Avatar>{initials}</Avatar>
        </PopoverTrigger>
        <PopoverContent align="end" sideOffset={12} className="p-0">
          <button
            className="w-full p-4 text-left text-living-coral-700 hover:bg-gray-5"
            onClick={handleClick}
          >
            {t('navigation_logout_button')}
          </button>
        </PopoverContent>
      </Popover>

      <UnsavedChangesWarning
        open={isOpen}
        onConfirm={logout}
        onCancel={toggleOpen}
      />
    </>
  );
}
