import { useTranslations } from 'next-intl';

import { DialogComposed } from '@/app/components/ui/Dialog/DialogComposed';
import Icon from '@/app/components/ui/Icon';

type UnsavedChangesWarningProps = {
  readonly open?: boolean;
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
};

export default function UnsavedChangesWarning({
  open,
  onConfirm,
  onCancel,
}: UnsavedChangesWarningProps) {
  const t = useTranslations();

  return (
    <DialogComposed
      open={open}
      title={t('unsaved_changes_title')}
      TitleIcon={<Icon icon="ALERT_05" />}
      description={t('unsaved_changes_description')}
      hideCloseButton
      footerActionLabel={t('unsaved_changes_primary_button')}
      footerActionCallback={onConfirm}
      footerCloseLabel={t('unsaved_changes_secondary_button')}
      footerCloseCallback={onCancel}
    />
  );
}
