import type { HTMLAttributes } from 'react';

import Icon from '@/app/components/ui/Icon';
import type { TIconName } from '@/app/consts/icons';
import { cn } from '@/utils/ui';

type AvatarProps = HTMLAttributes<HTMLDivElement> & {
  readonly icon?: TIconName;
};

const Avatar = ({ icon, children, className, ...props }: AvatarProps) => {
  return (
    <div
      className={cn(
        'flex size-10 items-center justify-center gap-2 rounded-full bg-sandstone-light p-2 text-sm text-helsinki-blue',
        className,
      )}
      {...props}
    >
      {icon && <Icon icon={icon} height={20} width={20} />}
      {children}
    </div>
  );
};

export default Avatar;
